.basic-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0rem 5rem;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem 0rem 2rem;
}

.contact-form-row {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem 0rem 0rem;
}

.contact-form-input {
  padding: 1rem;
  font-size: 1.4rem;
  border-radius: 0.8rem;
}

.contact-form-input-message {
  padding: 1rem;
  font-size: 1.4rem;
  height: 10rem;
  border-radius: 0.8rem;
}

.contact-form-submit {
  padding: 1rem 4rem 1rem;
  font-size: 1.4rem;
  border-radius: 0.2rem;
}

@media (max-width: 1380px) {
}

@media (max-width: 768px) {
}

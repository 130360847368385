.service-container {
  width: 30rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 10rem; /* top right-left bottom */
  border-radius: 1rem;
}

.service-icon {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  text-align: center;
}

/* Card  Styling */
.service-card-title {
  margin: auto;
  text-align: center;
  margin-top: 25px;
  font-weight: 700;
  font-size: 30px;
  line-height: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.service-card-description {
  margin-top: 35px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}
.talk-button {
  background-color: #a066fb;
  color: white;
  width: 90px;
  padding: 10px 18px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin-top: 20px;
  font-size: 14px;
  cursor: pointer;
}
.card-footer-button-div {
  display: flex;
  justify-content: space-around;
}

/* Media Query */
@media (max-width: 1380px) {
  .service-container {
    margin: 5rem;
  }

  .card-title {
    font-size: 25px;
  }
}
@media (max-width: 768px) {
  .mask {
    clip-path: none;
    position: relative;
  }
  .talk-card-title {
    margin-top: 30px;
  }
  .talk-card-image {
    width: 100%;
  }

  .square {
    width: 100%;
    height: auto;
    padding-bottom: 30px;
  }
}

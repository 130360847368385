.main {
  width: 90%;
  margin: 0px auto;
  margin-top: 4rem;
}

.greeting-div {
  padding: 0rem 4rem 0rem 0rem;
}

.greeting-title {
  padding: 0;
  margin: 0;
  font-size: 6rem;
  line-height: 1;
  font-weight: 600;
  text-align: left;
}

.greeting-paragraph-test {
  text-align: left;
  line-height: 1;
  font-size: 1.5rem;
}

.greeting-text-div {
}

.greeting-img-div {
  margin: 0rem 0rem 0rem 0rem;
}

.greet-main {
  margin: 0px auto;
}

.gretting-main {
  width: 90%;
  margin: 0px auto;
}

.greeting-main {
  display: flex;
}

.greeting-main-div > * {
  flex: 1;
  padding: 4rem;
}

.greeting-main-div {
  display: flex;
  overflow: hidden;
}

.greet-contact-button {
  padding: 4rem;
  display: flex;
  justify-content: center;
}

/* .greet-button-style:hover {
  background-color: blue;
} */

.greet-button-style {
  display: "flex";
  padding: 1rem 5rem 1rem 5rem;
  border-radius: 2rem;
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-title {
    font-size: 3rem;
  }

  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    margin-top: 0px;
    text-align: center;
  }
  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    margin-right: 0px;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }

  .greeting-main-div {
    flex-direction: column;
  }

  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.services {
  padding: 4rem;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

/* Media Query */
@media (max-width: 1380px) {
  .services {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .talk-header-title {
    font-size: 30px;
    text-align: center;
  }
  .talk-header-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
}

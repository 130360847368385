.worktogether-header {
  font-size: 4rem;
  line-height: normal;
  font-family: "Google Sans Regular";
  text-align: center;
}

.worktogether-description {
  font-size: 1.5rem;
  line-height: normal;
  font-family: "Google Sans Regular";
  text-align: center;
}

.full-width-image-div {
  height: 30rem;
  overflow: hidden;
}

.full-width-image-img {
  width: 100%;
}

@media (max-width: 1380px) {
  .full-width-image-div {
    height: 15rem;
  }
}

@media (max-width: 768px) {
  .full-width-image-div {
    height: 10rem;
  }
}
